import React from "react"
import ContactCTACard from "./Components/ContactCTACard"
import styled from "@emotion/styled"

export default function ContactCTA({ h2, h5, scrollTo, CTAmessage,subtitle="Your personalized Data Management Blueprint"}) {
  return (
    <Container>

      {h2 !== undefined || h5 !== undefined ?
        <div className="Contact-Title">
          <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
          <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
        </div> : ""
      }

      <ContactInfoCtn>
        <ContactCTACard
          title={"Discovery Call"}
          CTA={"BOOK A SESSION"}
          link={"https://calendly.com/nadiapetiot/discovery-call"}
          CONVERSION_ID={process.env.GATSBY_LINKEDIN_CONVERSION_CALENDLY_ID}
        >
          <svg
            id="boy-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 93 117"
          >
            <use href="/icons-sprite-map.svg#boy-icon" />
          </svg>
        </ContactCTACard>

        <ContactCTACard
          title={"Consulting Session"}
          subTitle={subtitle}
          CTA={CTAmessage}
          link={"https://calendly.com/nadiapetiot/tailored-data-plan"}
          isActive
          displayPricing
          isLimitedOffer
          CONVERSION_ID={process.env.GATSBY_LINKEDIN_CONVERSION_CALENDLY_ID}
        >
          <img src="/main-head.png" alt="advanced" />
        </ContactCTACard>

        <ContactCTACard
          ScrollToForm
          title={"Get Your Quote"}
          CTA={"CONTACT US"}
          scrollTo={scrollTo}
          isLast
        >
          <img src="/folder.png" alt="advanced" />
        </ContactCTACard>
      </ContactInfoCtn>
    </Container>
  )
}

const Container = styled.div`
  width : 80%;
  margin:auto;
`
const ContactInfoCtn = styled.div`
  max-width: 55.129rem;
  margin: 4rem auto;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`


