import React, { useState } from "react"

import ReCAPTCHA from "react-google-recaptcha"
import { css } from "@emotion/react"
import { LinkedinInsight } from "../../Tools/Linkedin"
import styled from "@emotion/styled"

export default function ContactForm() {

  const CONVERSION_ID = process.env.GATSBY_LINKEDIN_CONVERSION_CONTACT_ID
  const [isDisabled, setisDisabled] = useState(true);

  const onSubmitWithReCAPTCHA = async () => {
    setisDisabled(false)
  }
  const expiresHandler = ()=>{
    setisDisabled(true)
  }

  return (
    <div css={ContactFormStyles} style={{ width: '100%'}}>


      <div id="contact-Form">
        <h2>Call our trial data experts</h2>

        <div className="contact-info-phone">

          <svg
            id="phone-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -9 99 117"
          >
            <use href="/icons-sprite-map.svg#phone-icon" />
          </svg>

          <div className="left">
            <div>+33 (0)1 77 62 45 25</div>
            <div>Monday-Friday | 8AM-5PM UTC</div>
          </div>
        </div>

        <div className="contact-info-email">
          <div>Email</div>
          <div>Get An Answer Within 24 hours</div>
        </div>
        <form
          id="contact-form"
          action="https://getform.io/f/e4fcc818-764d-4b38-a1f6-ab692c5d2198"
          method="POST"
        >
          <input
            className="form-field"
            type="text"
            name="name"
            placeholder="Your Name...*"
            required
          />
          <input
            className="form-field"
            type="email"
            name="email"
            placeholder="Your Email or Phone Number...*"
            required
          />
          <textarea
            className="form-field"
            name="message"
            placeholder="About Your Project..."
          ></textarea>
          <div id="checkbox-ctn">
            <input
              name="expert-check"
              id="expert-input"
              type="checkbox"
              defaultChecked
            />
            <label htmlFor="expert-input">
              I would like to be called back by an expert
            </label>
          </div>
          <div id="checkbox-ctn">
            <ReCAPTCHA
             sitekey={process.env.GATSBY_G_RECAPTCHA_KEY_CONTACT}
             onChange={onSubmitWithReCAPTCHA}
             onExpired={expiresHandler}
            />
          </div>
          <SubmitButton type="submit" disabled={isDisabled}>
            Submit
            {CONVERSION_ID &&
            <LinkedinInsight CONVERSION_ID={CONVERSION_ID}/>}
          </SubmitButton>
        </form>
      </div>
    </div>
  )
}

const SubmitButton = styled.button`
  ${props => props.disabled && css`
      opacity: 0.5;
    cursor: not-allowed;
    `} 
  width: initial;
  background-color: #ffffff;
  color: #0c0b29;
  border-radius: 0.144rem;
  padding: 1rem 5.28rem;
  text-transform: uppercase;
  margin: auto;
  border: 0;
  cursor: pointer;
  display: flex;
`

const ContactFormStyles = css`

  #checkbox-ctn {
    display: flex;
    margin: 2rem 2.4rem;
    background-color: transparent;
  }

  #checkbox-ctn label {
    color: #fafafb;
  }

  #checkbox-ctn > #expert-input {
    width: fit-content;
    width: -moz-fit-content;
    margin-right: 1.2rem;
    position: relative;
    top: 0.14rem;
    padding: 0.5rem;
  }

  .contact-info-email {
    margin: 2rem auto;

    div:first-of-type{
      color: #fff;
      margin-bottom: 1rem;
      font-size: 24px;
      font-weight: 400;
      text-align: center
    }

    div:last-of-type {
      color: grey;
      font-weight: 200;
      font-size: 16px;
      text-align: center
    }
  }

  #contact-form {
    max-width: 40rem;
    padding-bottom: 6rem;
    margin: auto;
  }

  #contact-form input,
  #contact-form textarea {
    display: block;
    width: 100%;
    background-color: transparent;
    padding: 1.44rem;
    box-sizing: border-box;
    border: 0.18rem solid #ffffff;
    margin-bottom: 2rem;
    border-radius: 0.72rem;
    color: #fafafb;
  }

  #contact-form textarea {
    min-height: 15rem;
  }

  #contact-form input::placeholder,
  #contact-form textarea::placeholder {
    font-weight: 300;
    color: #f4f4f5;
  }

  #contact-form input[type="submit"] {
    width: initial;
    background-color: #ffffff;
    color: #0c0b29;
    border-radius: 0.144rem;
    padding: 1rem 5.28rem;
    text-transform: uppercase;
    margin: auto;
    border: 0;
    cursor: pointer;
  }

  #phone-icon {
    width: 4.7527rem;
    height: 5.61689rem;
  }

  .contact-info-phone {
    margin: auto;
    max-width: 350px;
    color: #fff;
    display: flex;

    svg {
      transform: rotate(15deg);
      position: relative;
      left: -17px;
    }

    .left {
      flex-direction: column;
      width: 100%;

      div:first-of-type {
        font-size: 24px;
        font-weight: 400;
        margin: 1.1rem 0 0.9rem 0;
      }

      div:last-of-type {
        color: grey;
        font-weight: 200;
        font-size: 16px;
      }
    }
  }
  
  
  @media (max-width: 767px){
    
    #contact-Form{
      margin: auto 3em;
    }
  }
`