import styled from "@emotion/styled"

export const Title = styled.p`
  font-size: 2rem;
  color: #fafafb;
  margin-bottom: 2.157rem;
  width: 90%;
  @media (max-width: 767px) {

    margin: 0.5rem auto
  }
`

export const ButtonBook = styled.button`
  margin: 1rem auto 0.5rem auto;
  width: 60%;
  border-radius: 0.22rem;
  color: #fafafb;
  border: 0.18rem solid #ffffff;
  background-color: transparent;
  cursor: pointer;
  
  @media (max-width: 960px) {
    width: 80%;
  }

  @media (max-width: 850px) {
    width: 94%;
  }
`
export const Subtitle = styled.p`
  width: 60%;
  color: #2a78e4 !important;
  margin: 3rem auto;

  @media (max-width: 767px) {
    margin: 1.5rem auto 0.5rem auto;
  }
`
export const ContactInfoCtn = styled.div`
  text-align: center;
  max-width: 250px;
  margin: 0.4rem auto auto auto;

  a {
    text-decoration: none;
  }



  ${props => props.isActive && `
    border-radius: 0.7rem;
    border: 0.18rem solid #fff;
    box-shadow: rgb(255 255 255 / 47%) 0px 0px 4px 1px;
    max-width: 290px !important;  
  `};

  @media (max-width: 767px) {
    &:first-of-type {
      margin: 0 0 2em 0 !important;
    }

    & > span {
      margin-top: 2.157rem;
    }
  }
`

export const LimitedOffer = styled.div`
  width: 100%;
  border-top: 0.18rem solid #ffffff;
  color: #fafafb;
`

export const CTAContainer = styled.div`
  height: 100%;

  img {
    margin: 2rem auto;
    height: 89px;
  }

  svg {
    margin: 2rem auto;
    max-height: 89px;
  }
  
  &:last-of-type{
    margin-left: 4rem;
  }

  &:first-of-type {
    margin-right: 4rem;
  }

  @media (max-width: 767px) {
    &:last-of-type{
      margin: auto ;
    }

    &:first-of-type {
      margin: auto;
    }

  }
`

export const Pricing = styled.div`
  display: block;
  font-weight: 300;
  color: #8b8b8b;
  text-align: center;
  margin-bottom: 2.5rem;
`

export const MainContactInfo = styled.div`

  @media (max-width: 767px) {
    margin: 0 !important;
  }
`

